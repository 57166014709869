
import { objectDifference } from "@/helpers";
import {
  atlasFileCategoryMapActions,
  atlasFileCategoryMapGetters,
  atlasFileCategoryMapMutations,
  atlasFileCategoryMapState
} from "@/store/modules/atlasFileCategory";
import {
  atlasfileMapActions,
  atlasfileMapGetters,
  atlasfileMapState
} from "@/store/modules/atlasfile";
import * as types from "@/store/mutation-types";
import { documentCategoryForm } from "@/forms/admin/documentCategory";
import { FormBlock } from "@/components/FormBuilder/types";
import { tableColumns } from "../ListAtlasFiles/TableColumns";
import { getPathAPI } from "@/helpers";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import Vue from "vue";

export default Vue.extend({
  name: "editDocumentCategory",
  props: {},
  components: {
    NoResultsFound
  },
  data() {
    return {
      error: "",
      validationData: {},
      loadingText: "",
      tableColumns: tableColumns,
      filters: [
        {
          field: "isPublic",
          title: "Is Public",
          options: [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" }
          ]
        },
        {
          field: "type",
          title: "File Type",
          options: [
            { label: "PDF", value: "application/pdf" },
            { label: "CSV", value: "text/csv" },
            { label: "PNG", value: "image/png" },
            { label: "JPEG", value: "image/jpeg" }
          ]
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        }
      ]
    };
  },
  mounted() {
    this.getOneAtlasFileCategory(this.$route.params.categoryId).then(() => {
      this.setEdit(this.category);
      this.fetchAssociatedFiles({
        category: this.category.title,
        __limit: 50,
        __page: 1
      });
    });
  },
  methods: {
    ...atlasFileCategoryMapMutations({
      editField: types.SET_EDIT_FIELD,
      setEdit: types.SET_EDIT
    }),
    ...atlasFileCategoryMapActions([
      "updateAtlasFileCategory",
      "getOneAtlasFileCategory"
    ]),
    ...atlasfileMapActions(["getAtlasfiles", "deleteAtlasFile"]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "save":
          this.updateOneAtlasFileCategory();
          break;
        case "cancel":
          this.$router.push("/admin/document-categories").catch(() => {});
          break;
      }
    },
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    },
    async updateOneAtlasFileCategory(): Promise<void> {
      if (this.editing) {
        try {
          await this.updateAtlasFileCategory({
            id: this.editing._id,
            update: this.updatedFields
          });
          this.$router.push("/admin/document-categories").catch(() => {});
          this.$appNotifySuccess("Document update successful");
        } catch (error) {
          this.error = error as string;
          this.$bugSnagClient.notify(error);
          this.$appNotifyError(error.message);
        }
      }
    },
    async fetchAssociatedFiles(queryObject: any): Promise<void> {
      try {
        this.loadingText = "Loading files. Please wait...";
        this.$emit("scrollTop");
        if (this.category && this.category.title) {
          queryObject["category"] = this.category.title;
        }
        await this.getAtlasfiles({ query: queryObject });
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loadingText = "";
      }
    },
    downloadFile(item: any) {
      const accessToken = localStorage.getItem("access");
      const baseUrl = getPathAPI();
      const element = document.createElement("a");
      element.href = `${baseUrl}/file/view/${item._id}?accessToken=${accessToken}`;
      element.download = "";
      element.target = "_blank";
      element.click();
    }
  },
  computed: {
    ...atlasFileCategoryMapGetters(["getAtlasFileCategoryById"]),
    ...atlasFileCategoryMapState(["editing", "makingApiRequest"]),
    ...atlasfileMapState(["atlasfiles", "links"]),
    ...atlasfileMapGetters(["getTotalRecords"]),
    formFields(): FormBlock[] {
      return documentCategoryForm;
    },
    formValues(): any {
      return this.editing ? this.editing : {};
    },
    updatedFields(): any {
      if (this.editing) {
        const original = this.getAtlasFileCategoryById(this.editing._id);
        const edited = this.editing;
        return objectDifference(edited, original);
      } else {
        return {};
      }
    },
    formIsValid(): boolean {
      return !!(this.validationData as any).formIsValid;
    },
    category(): any {
      return this.getAtlasFileCategoryById(this.$route.params.categoryId);
    }
  }
});
