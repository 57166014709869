var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"showSearch":false,"topActionButtonPrimary":{
    text: 'Save',
    key: 'save',
    loading: _vm.makingApiRequest,
    disabled: !_vm.formIsValid || Object.keys(_vm.updatedFields).length <= 0
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancel'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[_c('form-builder',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],ref:"formElement",attrs:{"schemas":_vm.formFields,"formValues":_vm.formValues,"element-loading-text":_vm.loadingText},on:{"validationChanged":function($event){_vm.validationData = $event},"formFieldChanged":_vm.formFieldChangedHandler}}),_c('div',{staticClass:"text-lg mb-2"},[_vm._v("Associated Documents")]),_c('ListView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"element-loading-text":_vm.loadingText,"data":_vm.atlasfiles,"paginationLinks":_vm.links,"totalRecords":_vm.getTotalRecords,"tableColumns":_vm.tableColumns,"filters":_vm.filters},on:{"fetch-query":_vm.fetchAssociatedFiles,"tableRowClicked":_vm.downloadFile}},[_c('NoResultsFound',{attrs:{"slot":"notFound","message":"No Documents Found."},slot:"notFound"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }